import { BLOCK_TYPE } from '@/client/tenant'
import type {
  GetPageResponse,
  LandingPageBlock,
} from '@/client/tenant'
import type { Locale } from '@whispli/i18n/types'

export const getSupportedLocales = (
  possibleLocales: ReadonlyArray<Locale>,
  supportedLocales: ReadonlyArray<Partial<Locale>>,
): ReadonlyArray<Locale> => {
  const supportedLocaleCodes = supportedLocales.map((e) => e.code)
  return possibleLocales.filter((e) => supportedLocaleCodes.includes(e.code))
}

export const mapLegacyBlockType = (
  landingPageBlock: LandingPageBlock,
  landingPageBlockTypes: GetPageResponse['data']['landing_page_block_types']
): LandingPageBlock => {
  const landingPageBlockType = landingPageBlockTypes
    .find((b) => landingPageBlock.landingPageBlockTypeId === b.id)

  let type: BLOCK_TYPE
  switch (landingPageBlockType?.name) {
    case 'image':
      type = landingPageBlock.order === 1
        ? BLOCK_TYPE.LOGO
        : BLOCK_TYPE.IMAGE
      break
    case 'text':
      type = landingPageBlock.order === 2
        ? BLOCK_TYPE.DESCRIPTION
        : BLOCK_TYPE.TEXT
      break
    case 'channel':
      type = BLOCK_TYPE.CHANNEL_LINK
      break
    case 'header':
      type = BLOCK_TYPE.TITLE
      break
    default:
      type = BLOCK_TYPE.DEFAULT
  }

  return {
    ...landingPageBlock,
    type,
  }
}
