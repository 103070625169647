import type { LandingPageBlock, PageData } from '../types'
import { BLOCK_TYPE } from '../constants'
import type { Locale } from '@whispli/i18n/types'
import { getSupportedLocales } from './utils'
import { V2_RELEASE_DATE } from '@/shared/constants'

export class PageAdapter {
  public readonly channelLinks: ReadonlyArray<LandingPageBlock>
  public readonly description: Readonly<LandingPageBlock>
  public readonly id: string
  public readonly image: Readonly<LandingPageBlock>
  public readonly logo: Readonly<LandingPageBlock>
  public readonly supportedLocales: ReadonlyArray<Locale>
  public readonly text: ReadonlyArray<LandingPageBlock>
  public readonly title: Readonly<LandingPageBlock>
  private readonly blocks: ReadonlyArray<LandingPageBlock>

  constructor(
    private data: PageData,
    private locales: ReadonlyArray<Locale>,
  ) {
    this.id = data.id
    this.blocks = (data?.blocks ?? [])
    this.channelLinks = this.blocks.filter((e) => e.type === BLOCK_TYPE.CHANNEL_LINK)
    this.description = this.blocks.find((e) => e.type === BLOCK_TYPE.DESCRIPTION)!
    this.image = this.blocks.find((e) => e.type === BLOCK_TYPE.IMAGE)!
    this.logo = this.blocks.find((e) => e.type === BLOCK_TYPE.LOGO)!
    this.text = this.blocks.filter((e) => e.type === BLOCK_TYPE.TEXT)
    this.title = this.blocks.find((e) => e.type === BLOCK_TYPE.TITLE)!
    this.supportedLocales = getSupportedLocales(
      this.locales,
      (data.translationSettings ?? []).map((e) => e.locale)
    )
  }

  public get mobileDeepLink(): string | null {
    return this.data.mobileDeepLink ?? null
  }

  public get translationSettings(): PageData['translationSettings'] {
    return this.data.translationSettings ?? []
  }

  public get isLegacy(): boolean {
    return new Date(this.data.createdAt) < V2_RELEASE_DATE
  }
}
